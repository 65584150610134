@use "mixins" as *;
@mixin form {
    box-sizing: border-box;
    margin: 0;
    background-color: var(--content-bg--color-lighter);
    border: .4px solid var(--color-border-tertiary);
    padding: .3rem;
    outline: none;
    border-radius: var(--radius);
    max-height: 1.6rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form__header {
    display: flex;
    gap: 2rem;
    align-items: flex-start;
}

.form__icon {
    width: 4rem;
    height: 4rem;
    color: var(--color-icon);

    &.-small {
        width: 1rem;
        height: 1rem;
    }
}

.form__button {
    @include body;
    text-align: center;
    border: 1px solid var(--color-border-tertiary);
    border-radius: var(--radius-max);
    color: var(--color-text-secondary);
    padding: 0.04rem 0.5rem;
    gap: .4rem;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.form__button:hover .form__icon {
    color: var(--color-icon-focus);
}

.form__header-container {
    display: flex;
    flex-direction: column;
    gap: .8rem;

    &.-card {
        width: 10rem;
    }

    &.-error {
        gap: .2rem
    }
}

.form__block {
    display: flex;
    gap: 1rem;
    width: 20rem;
    align-items: center;

    &.-column {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }

    &.-margin-top {
        margin-top: 1rem;
    }

    &.-long {
        width: 27rem;
    }

    &.-full-width {
        width: 100%;
    }

    &.-hidden {
        display: none;
    }

    &.-flex-end {
        justify-content: flex-end;
    }

    &.-fit-content {
        width: fit-content;
    }
}

.form__text {
    @include body;
    @include form;
    width: 8rem;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.-optional {
        color: var(--color-text-secondary);
    }

    &:focus-visible, &:focus {
        border-color: var(--color-border-focus);
    }

    &.-number {
        width: 3rem;
        text-align: center;
    }

    &.-card {
        padding: 0 .6rem;
        width: auto;

        &.-date {
            min-width: 6rem;
        }
    }

    &.-long {
        width: 15rem;
    }

    &.-hard-long {
        width: 11rem;
    }

    &.-full {
        width: 100%;
    }
}

.form__textarea {
    @include body;
    background-color: transparent;
    border-radius: var(--radius);
    border: .4px solid var(--color-border-secondary);
    padding: .5rem;
    width: calc(100% - 1rem);
    height: auto;
    resize: none;
    outline: none;

    &.-car, &.-note {
        width: 100%;
    }

    &.-car-view {
        height: 10rem;
    }
}

.form__error {
    @include body;
    color: var(--color-text-warning);
    margin: 0;

    &.-hidden {
        display: none;
    }
}

.form__selecter {
    @include body;
    @include form;
    width: 7rem;

    &.-long {
        width: 15rem;
    }

    &.-full {
        width: 100%;
    }
}

.form__title {
    @include title;
    margin: 0;
}

.form__subtitle {
    @include subtitle;
    margin: 0;
    font-size: .9rem;

    &.-secondary {
        color: var(--color-text-secondary);
    }

    &.-long {
        width: 7rem;
    }

    &.-full {
        width: 10rem;
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.form__checkbox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: .4rem;
    width: 100%;

    &.-no-width {
        width: auto;
    }
}

.form__label-rich-text {
    display: ruby;
    width: 100%;
}

.form__link {
    color: var(--color-text-secondary);
    gap: 0;
    border-radius: 0;
    text-decoration: underline !important;
    padding-block: 0;
    padding-inline: 0;
    width: fit-content;

    &:hover {
        background-color: transparent;
        color: var(--color-text-primary);
    }
}

.form__submit {
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    width: 5.5rem;
    background-color: var(--color-button-bg-submit);
    padding: .2rem;

    &.-a {
        width: 5.1rem;
    }

    &.-margin-top {
        margin-top: 1rem;
    }

    &:hover {
        background-color: var(--color-button-bg-submit);
    }

    &.-deletion {
        background-color: var(--color-bg-warning);
    }
}

.form__submit-text {
    @include body;
    color: var(--color-text-quaternary);
    opacity: .9;
    margin: 0;
}

.form__submit:hover .form__submit-text {
    opacity: 1;
}

@media (max-width: $breakpoint-mobile) {
    .form__header {
        flex-direction: column;
    }

    .form__icon {
        display: none;
    }

    .form__block.-long {
        width: 14rem;
    }

    .form__block.-column-mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}
