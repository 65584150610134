@use "mixins" as *;
.register {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--content-bg--color-lighter);
    padding-top: 4rem;
}

.register__title {
    @include title;
    background: linear-gradient(to right, var(--color-text-primary), var(--color-text-tertiary));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.register__text {
    @include subtitle;
    color: var(--color-text-primary);

    &.-cropped {
        @include body;
        max-width: 15rem;
    }
}

.register__underline {
    text-decoration: underline;
}

.register__body {
    @include body;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-text-secondary);
    margin-top: -1.5rem;
    text-align: center;
    align-self: center;
    width: 10rem;

    &:hover {
        color: var(--color-text-primary);
    }
}

.register__form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    border: 1px solid var(--color-border-quaternary);
    border-radius: var(--radius);

    &.-large {
        width: 25rem;
    }
}

.register__block {
    display: flex;
    width: 100%;
    gap: 1rem;
    justify-content: center;
    align-items: flex-start;

    &.-column {
        flex-direction: column;
    }
}
