@use "mixins" as *;
.car__object {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    padding: 0 1rem 1rem;
    border-radius: var(--radius);
    background-color: var(--content-bg--color-lighter);
}

.form.-car-incharge {
    width: 33rem;
}

.car__delayed-works {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.car__delayed-work {
    display: flex;
    flex-direction: column;
    border: .4px solid var(--color-border-tertiary);
    border-radius: var(--radius);
    padding: .4rem;
    min-width: 15rem;

    &.-last {
        border-color: var(--color-border-focus);
    }
}

.car__go-back-icon {
    transform: rotate(180deg);
}

.body__toolbar-button-menu:hover .car__go-back-icon {
    color: var(--color-icon-focus);
}

.replacement__label {
    @include body;
    color: var(--color-text-secondary);
    display: flex;
    width: 70%;
}

.form__text.-car-state {
    @include subbody;
    display: flex;
    align-items: center;
    gap: .4rem;
    margin-top: .2rem;

    &.-disable {
        opacity: .4;
    }
}

.car__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.car__empty-box {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: .4px solid var(--color-border-tertiary);
    border-radius: var(--radius);
    background-color: var(--content-bg--color-lighter);
    padding: 1rem;
    min-width: 35rem;
    min-height: 15rem;
    overflow: hidden;

    &.-insurances {
        transform: translateY(-15%);
    }
}

.car__empty-icon {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(-20%, -30%);
    color: var(--color-icon);
    opacity: .1;
    z-index: 1;
}

.car__empty-header {
    @include subtitle;
}

.car__empty-button {
    @include subtitle;
    background-color: var(--color-button-bg-submit);
    color: var(--color-text-quaternary);
    padding: .4rem .8rem;
    border-radius: var(--radius);
    cursor: pointer;
    z-index: 2;
    opacity: 1;

    &:hover {
        background-color: var(--color-button-bg-submit-full-hover);
        color: var(--color-text-quaternary);
        opacity: 1;
    }
}

.car__empty-add {
    width: 1.6rem;
    height: 1.6rem;
}

.car__name {
    @include body;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 1.2rem - 3.2rem - .4rem);
    text-align: left;

    &.-tooltip {
        width: 100%;
    }

    &.-search-result {
        background-color: var(--search-highlighted-bg);
        color: var(--search-text-color);
        box-shadow: 0 0 4px 1px var(--search-box-shadow-color);
        height: 1.1rem;
        margin-top: .15rem;
        align-items: center;
        display: flex;
    }
}

.car__followup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: .4rem;
    gap: .2rem;
}

.car__replacement,
.car__tow-truck {
    display: flex;
    align-items: center;
    width: 1.6rem;
}

.car__replacement-icon,
.car__tow-truck-icon,
.car__seriously-damaged-icon {
    width: 1.2rem;
    height: 1.2rem;
}

.car__seriously-damaged-icon {
    color: var(--color-text-warning);
}

.car__tow-truck-icon {
    color: var(--color-icon);
}

.car__replacement-icon {
    color: var(--color-text-submit);

    &.-finished {
        color: var(--color-text-success);
    }
}

.car__tooltip-text {
    &.-title {
        @include subtitle;
    }

    &.-subtitle {
        @include body;

        &.-blue {
            color: var(--color-text-submit);
        }
    }

    &.-subbody {
        @include subbody;
    }

    &.-red {
        color: var(--color-text-warning);
    }

    &.-finished {
        color: var(--color-text-success);
    }
}

.car__tooltip-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: .6rem;
}

.replacement-car-timeline.-detail > .p-timeline-event {
    flex-direction: row;
    max-width: 100rem;
    min-width: 0;

    &:nth-child(odd) {
      min-height: 3rem;
    }
}

.replacement-car-timeline.-detail > .p-timeline-event > .p-timeline-event-opposite {
    max-width: 5.9rem !important;
    min-width: 5.9rem !important;
    padding-left: 0 !important;
}

.replacement-car-timeline > .p-timeline-event > .p-timeline-event-opposite {
    text-align: left !important;
}

.car-row-separator {
  width: .4rem;
  height: .4rem;
  background-color: var(--color-icon);
  border-radius: var(--radius-max);
}

.car-history-log-user {
  color: var(--color-text-primary);
}

.car-history > .p-timeline-event > .p-timeline-event-opposite {
  display: none;
}

.car-state > .p-splitbutton-dropdown {
  display: none;
}

.car-state-container:hover > .car-state > .p-splitbutton-button {
  cursor: pointer !important;
  transform: scale(1.2);
}

.car-state-menu {
  padding: .4rem;
  background-color: var(--content-bg--color-lighter) !important;
  border-radius: var(--radius) !important;
  border: .4px solid var(--color-border-secondary) !important;
  box-shadow: 0 0 1rem black !important;
}

.car-state-menu > .p-tieredmenu-root-list {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}

.car-state-menu > .p-tieredmenu-root-list > .p-tieredmenu-item {
  @include body;
  margin: 0;
  padding: 0;
}

.p-tieredmenu.car-state-menu {
  min-width: 0px !important;
}
