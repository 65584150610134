@use "mixins" as *;
.admin__container {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-self: center;
    gap: 1rem;
    margin-top: 1rem;
}

.admin__title {
    @include title;
    font-weight: 700;
}

.admin__subtitle {
    @include subtitle;
}

.admin__body {
    @include body;
    color: var(--color-text-secondary);
    margin-top: -1rem;

    &.-highlight {
        color: var(--color-text-primary);
        margin: .5rem 0;
    }
}

.admin__section {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &.-row {
        flex-direction: row;
        align-items: center;
    }
}


.admin__label {
    @include body;
    color: var(--color-text-secondary);
    display: flex;
    width: 100%;
}

.admin__message {
    @include body;
    color: var(--color-text-success);

    &.-error {
        color: var(--color-text-warning);
    }
}

.admin__delimiter {
    width: 100%;
    height: .1rem;
    margin: 1rem 0;
    background-color: var(--color-border-tertiary);
}

.admin__button {
    @include body;
    border-radius: var(--radius);
    background-color: var(--color-button-bg-submit);
    opacity: .9;
    color: var(--color-text-quaternary);

    &:hover {
        opacity: 1;
        background-color: var(--color-button-bg-submit);
    }
}

.admin__share {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    justify-content: flex-end;
    width: 25%;
}

.admin__link,
.admin__input {
    @include body;
    box-sizing: border-box;
    margin: 0;
    background-color: var(--content-bg--color-lighter);
    border: .4px solid var(--color-border-tertiary);
    padding: .3rem;
    outline: none;
    border-radius: var(--radius);
    max-height: 1.6rem;
    width: 75%;
}

.admin__input {
    margin-bottom: 1rem;

    &:read-only {
       color: var(--color-text-secondary);
    }
}

.admin__users {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin__user {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.admin__user-profile {
    display: flex;
    align-items: center;
    gap: 1.2rem;
    min-width: 16rem;
}

.admin__user-avatar {
    @include body;
    display: flex;
    width: 2rem;
    height: 2rem;
    border-radius: 999px;
    justify-content: center;
    align-items: center;
    color: var(--color-text-quaternary);
}

.admin__user-info {
    display: flex;
    flex-direction: column;
}

.admin__user-name {
    @include body;
}

.admin__user-email {
    @include body;
    color: var(--color-text-secondary);
}

.admin__user-status {
    @include body;
    opacity: .8;
    min-width: 5rem;
    justify-content: flex-start;
}

.admin__user-actions {
    display: flex;
    gap: .5rem;
    height: 1.5rem;
    min-width: 10rem;
    justify-content: flex-end;
}

.admin__legals {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10rem;
}

.admin__legal {
    width: fit-content;
    @include body;
    opacity: .9;

    &:hover {
        opacity: 1;
    }
}

@media (max-width: $breakpoint-l) {
    .admin__container {
        width: 70%;
    }
}

@media (max-width: $breakpoint-m) {
    .admin__container {
        width: 95%;
    }

    .admin__share {
        width: 35%;
    }

    .admin__link {
        width: 65%;
    }
}

@media (max-width: $breakpoint-mobile) {
    .admin__container {
        margin-top: 0;
    }

    .admin__user-status {
        display: none;
    }

    .admin__share, .admin__link, .admin__user-info, .admin__user-actions {
        width: 100%;
    }

    .admin__user-actions {
        justify-content: flex-end;
        margin-top: .5rem;
    }

    .admin__section.-row, .admin__user {
        flex-direction: column;
    }
}
