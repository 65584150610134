@use "mixins" as *;

.timeline__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 20rem;

    &.-contract {
        padding-bottom: 0;
    }
}

.timeline {
    width: .1rem;
    background-color: var(--color-border-primary);
    gap: 2rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.timeline__block {
    display: flex;
    gap: .6rem;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 45rem;
    background-color: var(--content-bg--color-light); 

    &.-flex-start {
        align-items: flex-start;
    }

    &.-transparent {
        background-color: transparent;
    }
}

.timeline__object {
    display: flex;
    flex-direction: column;
    margin-left: -.9rem;

    &.-replacement, &.-staff {
        margin-left: -.8rem;
    }

    &.-shift {
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding-inline: 6px;
        margin-bottom: -.1rem;
    }
}

.timeline__point {
    width: 1rem;
    height: 1rem;
    border: 2px solid var(--color-border-secondary);
    border-radius: 999px;

    &.-small {
        width: .4rem;
        height: .4rem;
        border: none;
        background-color: var(--color-border-secondary);
        margin-left: .4rem;
    }

    &.-in-progress {
        border-color: var(--car-state-coming);
    }

    &.-coming {
        border-color: var(--car-state-todo-border);
    }

    &.-finished {
        border-color: var(--car-state-finished);
    }
}

.timeline__label {
    @include body;
    display: flex;
    justify-content: space-between;
    color: var(--color-text-secondary);
    width: 20%;

    &.-no-width {
        width: 100%;
    }
}

.timeline_name {
    color: var(--color-text-primary);
}

.link_name {
    @include body;
    color: var(--color-text-primary);

    &.-no-padding {
        padding-inline: 0;

        &:hover {
            padding-inline: 6px;
        }
    }
}
