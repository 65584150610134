@use "mixins" as *;
.beta__menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: -1rem;
}

.beta {
    background-color: #ffdb3e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius);
    margin: 0 1rem;
    padding: 0 .8rem;
    max-width: 4rem;
    max-height: 1.4rem;
}

.beta__text {
    @include subtitle;
    color: black;
}

.beta-mobile {
    display: none;
}

@media (max-width: $breakpoint-m) {
    .beta__menu {
        align-self: flex-end;
        margin-bottom: -2.8rem;
        margin-right: 2.5rem;
    }
}

@media (max-width: $breakpoint-mobile) {
    .beta-mobile {
        display: flex;
    }
}
