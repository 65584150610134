@use "mixins" as *;
.main-body {
    display: flex;
    width: calc(100% - 28px);
    height: calc(100% - 28px);
    background-color: var(--content-bg--color);
    margin: 14px;
    padding: 0px;
    overflow: hidden;
    font-family: "Inter", sans-serif;

    &.-home {
        flex-direction: column;
        overflow-y: auto;
        background-color: var(--content-bg--color);
        width: 100%;
        height: 100%;
        margin: 0;
    }
}

.global__spinner {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
