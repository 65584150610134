@use "mixins" as *;
:root {
    /* make 1rem = 16px */
    font-size: 100%;

    --radius: .3rem;
    --radius-max: 1rem;
    --base-z: 18rem;
    --base-x: 10%;

    --ease-out-quad: cubic-bezier(0.25,0.46,0.45,0.94);

    --breakpoint-l: #{$breakpoint-l};
    --breakpoint-m: #{$breakpoint-m};
    --breakpoint-s: #{$breakpoint-s};

    --color-homepage-border-header: #576c81;
    --color-homepage-border: #555555;
    --color-homepage-border-dark: #252525;

    --color-homepage-text-primary: #ffffff;
    --color-homepage-text-secondary: #969799;
    --color-homepage-text-tertiary: #808183;
    --color-text-black: #000000;

    --color-text-quaternary: #ffffff;
    --color-text-quaternary-secondary: #969799;

    --color-button-bg-shadow-homepage: #3a4e71f5;
    --color-button-bg-shadow-light-homepage: #3b4048f5;
    --color-button-bg-submit-homepage: #517fd0f5;

    --content-bg--color-homepage-lighter: #151a22;
    --content-bg--color-homepage-light: #0f1011;

    --color-linkedin: #0a66c2;
    --color-github: #ffffff;
    --color-instagram: #ff3040;

    --color-bg-bar-sheet-metal-work: #ebab15;
    --color-bg-bar-painting: #c20877;
    --color-bg-bar-mechanic: #00a684;

    --insurances-group-purple: #5e6ad2;
    --insurances-group-teal: #26b5ce;
    --insurances-group-green: #4cb782;
    --insurances-group-yellow: #f3be00;
    --insurances-group-orange: #f2994a;
    --insurances-group-pink: #f7c8c1;
    --insurances-group-red: #eb5757;

    --color-due-date: #ff9f07;
}

:root.ctx-dark-mode {
    --content-bg--color: #090909;
    --content-bg--color-dark: #2f2f2f;
    --content-bg--color-light: #0f1011;
    --content-bg--color-lighter: #151a22;
    --content-bg--color-focus: #161d2a;
    --content-bg--color-contrast: #27364f;
    --content-bg--floating-button: #0e336e;
    --color-bg-warning: #c21330;
    --color-bg-warning-light: #ee8383;
    --color-bg-warning-lighter: #ffdcdc;
    --color-bg-warning-tag: #cb2a2a;
    --color-bg-success-dark: #276447;
    --color-bg-success-light: #95ffcc;
    --color-bg-success-tag: #006836;
    --color-bg-feedback-light: #fffae6;
    --color-bg-feedback-tag: #a7590a;
    --transparent-bg-color: #00000054;
    --color-text-primary: #ffffff;
    --color-text-secondary: #969799;
    --color-text-tertiary: #808183;
    --color-text-quinquenary: black;
    --color-text-warning: #ff0000;
    --color-text-warning-tag: #ffffff;
    --color-text-success-tag: #ffffff;
    --color-text-feedback-tag: #ffffff;
    --color-text-submit: #2252acf5;
    --color-text-submit-focus: #004efff5;
    --color-text-success: #179f5d;
    --color-text-success-light: #11e07c;
    --color-text-feedback: #ffa500;
    --color-border-primary: #26282d;
    --color-border-secondary: #424548;
    --color-border-tertiary: #454851;
    --color-border-warning: #4d161f;
    --color-border-quaternary: #62697c;
    --color-border-success: #179f5d;
    --color-border-focus: #ffffff;
    --color-border-submit: #2252acf5;
    --color-border-header: #576c81;
    --color-icon: #939496;
    --color-icon-focus: #ffffff;
    --color-icon-hover: #ffffff;
    --color-button-bg-focus: #6b6f7612;
    --color-button-bg-object: #3a4e71f5;
    --color-button-bg-submit: #517fd0f5;
    --color-button-bg-submit-full: #3a4e71;
    --color-button-bg-submit-full-hover: #4373c8;
    --color-bg-note: #193048;
    --color-bg-note-focus: #24496f;

    --scrollbar-color: #5a5b5d;

    --search-highlighted-bg: #fff012;
    --search-text-color: #000000;
    --search-box-shadow-color: #ffffffba;

    --car-state-coming: #FFC107;
    --car-state-todo: transparent;
    --car-state-todo-border: #ffffff;
    --car-state-waiting: #ffffff;
    --car-state-repairing: #ff5602;
    --car-state-reassembly: #0044ff;
    --car-state-finished: #00ee7b;
    --car-state-breakage: #ff0000;
    --car-state-expertise: #ad00e4;
    --car-state-delivered: #4afffb;

    --car-state-coming-shaded: #ffc107c7;
    --car-state-finished-shaded: #00ee7bb1;

    --color-bg-mechanic: #0c1d2d;
    --color-bg-mechanic-hover: #122d47;

    --insurances-group-dark-grey: #95a2b3;
    --insurances-group-grey: #bec2c8;

    --car-followup-incomplete: #ff5602;
    --car-followup-complete: #00ee7b;

    --status-connected: #00ee7b;
}

:root.ctx-light-mode {
    --content-bg--color: #ececec;
    --content-bg--color-dark: #959595;
    --content-bg--color-light: #ffffff;
    --content-bg--color-lighter: #ececec;
    --content-bg--color-focus: #e4e4e4;
    --content-bg--color-contrast: #d3d2d2;
    --content-bg--floating-button: #cbdfff;
    --color-bg-warning: #c21330;
    --color-bg-warning-light: #ee8383;
    --color-bg-warning-lighter: #ffdcdc;
    --color-bg-warning-tag: #ffdcdc;
    --color-bg-success-dark: #276447;
    --color-bg-success-light: #11e07c47;
    --color-bg-success-tag: #11e07c47;
    --color-bg-feedback-light: #fffae6;
    --color-bg-feedback-tag: #fffae6;
    --transparent-bg-color: #00000054;
    --color-text-primary: #1b1b1b;
    --color-text-secondary: #767679;
    --color-text-tertiary: #5c5c5e;
    --color-text-warning: #ff0000;
    --color-text-warning-tag: #ff0000;
    --color-text-success-tag: #276447;
    --color-text-submit: #2252acf5;
    --color-text-submit-focus: #004efff5;
    --color-text-success: #179f5d;
    --color-text-success-light: #11e07c;
    --color-text-feedback: #ffa500;
    --color-text-feedback-tag: #ffa500;
    --color-border-primary: #cfc9c9;
    --color-border-secondary: #c6bfbf;
    --color-border-tertiary: #b3aeae;
    --color-border-warning: #dc0e2f;
    --color-border-quaternary: #62697c;
    --color-text-quinquenary: #ffffff;
    --color-border-success: #179f5d;
    --color-border-focus: #1b1b1b;
    --color-border-submit: #2252acf5;
    --color-border-header: #576c81;
    --color-icon: #575859;
    --color-icon-focus: #1b1b1b;
    --color-icon-hover: #1b1b1b;
    --color-button-bg-focus: #6b6f7612;
    --color-button-bg-object: #3a4e71f5;
    --color-button-bg-submit: #517fd0f5;
    --color-button-bg-submit-full: #8bb5ff;
    --color-button-bg-submit-full-hover: #4373c8;
    --color-bg-note: #e4f1ff;
    --color-bg-note-focus: #b1cce8;

    --scrollbar-color: #5a5b5d;

    --search-highlighted-bg: #fff012;
    --search-text-color: #000000;
    --search-box-shadow-color: #053dce4f;

    --car-state-coming: #ffc100;
    --car-state-todo: transparent;
    --car-state-todo-border: #b8b8b8;
    --car-state-waiting: #6b6b6b;
    --car-state-repairing: #ff4322;
    --car-state-reassembly: #0044ff;
    --car-state-finished: #00ee7b;
    --car-state-breakage: #9e1012;
    --car-state-expertise: #ad00e4;
    --car-state-delivered: #3acdca;

    --car-state-coming-shaded: #ffc107b6;
    --car-state-finished-shaded: #00ee7bb1;

    --color-bg-mechanic: #d4eaff;
    --color-bg-mechanic-hover: #c7dcf0;

    --insurances-group-dark-grey: #969a9f;
    --insurances-group-grey: #6e7b8b;

    --car-followup-incomplete: #ff5602;
    --car-followup-complete: #00ee7b;

    --status-connected: #00ee7b;
}

// primevue
:root {
    --p-form-field-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05);
    --p-overlay-select-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --p-overlay-popover-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    --p-overlay-modal-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
    --p-togglebutton-content-checked-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    --p-form-field-focus: #10b981;
    --p-form-background-field-focus: #ecfdf5;
    --p-form-field-border-color: var(--color-border-primary);
    --p-transition-duration: .2s;

    // inputtext
    --p-inputtext-color: var(--color-text-primary);
    --p-inputtext-background: transparent;
    --p-inputtext-padding-y: .5rem;
    --p-inputtext-padding-x: .75rem;
    --p-inputtext-border-color: var(--color-border-primary);
    --p-inputtext-transition-duration: var(--p-transition-duration);
    --p-inputtext-border-radius: var(--radius);
    --p-inputtext-shadow: var(--p-form-field-shadow);
    --p-inputtext-focus-ring-width: 0;
    --p-inputtext-focus-ring-color: transparent;
    --p-inputtext-focus-ring-offset: 0;
    --p-inputtext-focus-ring-style: none;
    --p-inputtext-focus-border-color: var(--p-form-field-focus);
    --p-inputtext-focus-ring-shadow: none;
    --p-inputtext-hover-border-color: var(--color-border-focus);
    --p-inputtext-invalid-border-color: var(--color-border-warning);
    --p-inputtext-filled-background: var(--content-bg--color-light);

    // floatlabel
    --p-floatlabel-color: var(--color-text-secondary);
    --p-floatlabel-weight: 500;
    --p-floatlabel-position-x: .75rem;
    --p-floatlabel-transition-duration: var(--p-transition-duration);
    --p-floatlabel-focus-color: var(--p-form-field-focus);
    --p-floatlabel-on-active-padding: 0 0.125rem;
    --p-floatlabel-on-active-background: var(--content-bg--color-lighter);
    --p-floatlabel-active-color: var(--color-text-secondary);
    --p-floatlabel-invalid-color: var(--color-text-warning);

    // autocomplete
    --p-autocomplete-overlay-background: var(--content-bg--color-lighter);
    --p-autocomplete-overlay-color: var(--color-text-primary);
    --p-autocomplete-overlay-border-color: var(--color-border-primary);
    --p-autocomplete-overlay-border-radius: var(--radius);
    --p-autocomplete-overlay-shadow: var(--p-overlay-select-shadow);
    --p-autocomplete-list-gap: .2rem;
    --p-autocomplete-list-padding: 0.25rem;
    --p-autocomplete-option-padding: 0.5rem 0.75rem;
    --p-autocomplete-option-color: var(--color-text-primary);
    --p-autocomplete-transition-duration: var(--p-transition-duration);
    --p-autocomplete-option-border-radius: var(--radius);
    --p-autocomplete-option-focus-background: var(--content-bg--color-focus);

    // tooltip
    --p-tooltip-gutter: 0.25rem;
    --p-tooltip-background: var(--content-bg--color-contrast);
    --p-tooltip-color: var(--color-text-primary);
    --p-tooltip-padding: 0.5rem 0.75rem;
    --p-tooltip-shadow: var(--p-overlay-popover-shadow);
    --p-tooltip-border-radius: var(--radius);

    // datepicker
    --p-datepicker-dropdown-width: 2.5rem;
    --p-datepicker-dropdown-border-radius: var(--radius);
    --p-datepicker-dropdown-background: var(--content-bg--color-lighter);
    --p-datepicker-dropdown-border-color: var(--color-border-primary);
    --p-datepicker-transition-duration: var(--p-transition-duration);
    --p-datepicker-dropdown-color: var(--color-text-secondary);
    --p-datepicker-dropdown-hover-background: var(--content-bg--color-light);
    --p-datepicker-dropdown-hover-border-color: var(--color-border-primary);
    --p-datepicker-dropdown-hover-color: var(--color-text-primary);
    --p-datepicker-dropdown-active-background: var(--content-bg--color-light);
    --p-datepicker-dropdown-active-border-color: var(--color-border-primary);
    --p-datepicker-dropdown-active-color: var(--color-text-primary);
    --p-datepicker-panel-padding: .75rem;
    --p-datepicker-panel-background: var(--content-bg--color-lighter);
    --p-datepicker-panel-color: var(--color-text-primary);
    --p-datepicker-panel-border-color: var(--color-border-primary);
    --p-datepicker-panel-border-radius: var(--radius);
    --p-datepicker-panel-shadow: var(--p-overlay-popover-shadow);
    --p-datepicker-header-padding: 0 0 .5rem 0;
    --p-datepicker-header-background: var(--content-bg--color-lighter);
    --p-datepicker-header-color: var(--color-text-primary);
    --p-datepicker-header-border-color: var(--color-border-secondary);
    --p-datepicker-title-gap: 0.5rem;
    --p-datepicker-title-font-weight: 500;
    --p-datepicker-month-view-margin: 0.5rem 0 0 0;
    --p-datepicker-day-view-margin: 0.5rem 0 0 0;
    --p-datepicker-transition-duration: var(--p-transition-duration);
    --p-datepicker-month-padding: 0.375rem;
    --p-datepicker-week-day-padding: 0.25rem;
    --p-datepicker-week-day-color: var(--color-text-primary);
    --p-datepicker-week-day-font-weight: 500;
    --p-datepicker-date-padding: 0.25rem;
    --p-datepicker-date-color: var(--color-text-secondary);
    --p-datepicker-date-hover-color: var(--color-text-primary);
    --p-datepicker-date-hover-background: var(--color-button-bg-focus);
    --p-datepicker-month-border-radius: var(--radius);
    --p-datepicker-date-width: 2rem;
    --p-datepicker-date-height: 2rem;
    --p-datepicker-date-border-radius: 50%;
    --p-datepicker-transition-duration: var(--p-transition-duration);
    --p-datepicker-date-color: var(--color-text-secondary);
    --p-datepicker-today-color: var(--color-text-black);
    --p-datepicker-today-background: #e2e8f0;
    --p-datepicker-date-selected-color: var(--color-text-quaternary);
    --p-datepicker-date-selected-background: var(--p-form-field-focus);
    --p-disabled-opacity: 0.6;

    // button
    --p-button-text-secondary-hover-background: var(--color-button-bg-focus);
    --p-button-text-secondary-color: var(--color-text-secondary);
    --p-button-icon-only-width: 2.5rem;
    --p-button-transition-duration: var(--p-transition-duration);

    // dialog
    --p-mask-background: rgba(0, 0, 0, 0.4);
    --p-mask-color: #e2e8f0;
    --p-dialog-border-radius: var(--radius);
    --p-dialog-shadow: var(--p-overlay-modal-shadow);
    --p-dialog-background: var(--content-bg--color-lighter);
    --p-dialog-border-color: var(--color-border-primary);
    --p-dialog-color: var(--color-text-primary);
    --p-dialog-header-padding: 1.25rem;
    --p-dialog-content-padding: 0 1.25rem 1.25rem 1.25rem;

    // checkbox
    --p-checkbox-width: .8rem;
    --p-checkbox-height: .8rem;
    --p-checkbox-checked-background: var(--p-form-field-focus);
    --p-checkbox-checked-border-color: var(--p-form-field-focus);
    --p-checkbox-transition-duration: var(--p-transition-duration);
    --p-checkbox-icon-size: .5rem;
    --p-checkbox-border-radius: var(--radius);
    --p-checkbox-border-color: #cbd5e1;
    --p-checkbox-background: var(--color-text-quaternary);
    --p-checkbox-icon-checked-color: var(--color-text-quaternary);
    --p-checkbox-hover-border-color: var(--color-border-focus);
    --p-checkbox-checked-hover-background: var(--p-form-field-focus);
    --p-checkbox-checked-hover-border-color: var(--p-form-field-focus);
    --p-checkbox-icon-checked-hover-color: var(--color-text-primary);

    // select
    --p-select-transition-duration: var(--p-transition-duration);
    --p-select-border-radius: var(--radius);
    --p-select-shadow: var(--p-form-field-shadow);
    --p-select-border-color: var(--color-border-secondary);
    --p-select-background: var(--content-bg--color-lighter);
    --p-select-padding-y: 0.5rem;
    --p-select-padding-x: 0.75rem;
    --p-select-color: var(--color-text-primary);
    --p-select-dropdown-width: 2.5rem;
    --p-select-dropdown-color: var(--color-text-secondary);
    --p-select-list-padding: 0.25rem;
    --p-select-list-gap: .2rem;
    --p-select-option-padding: 0.5rem 0.75rem;
    --p-select-option-color: var(--color-text-secondary);
    --p-select-transition-duration: var(--p-transition-duration);
    --p-select-option-border-radius: var(--radius);
    --p-select-option-focus-background: var(--color-button-bg-focus);
    --p-select-option-focus-color: var(--color-text-primary);
    --p-select-option-selected-color: var(--p-form-field-focus);
    --p-select-option-selected-focus-color: #065f46;
    --p-select-option-selected-background: var(--p-form-background-field-focus);
    --p-select-option-selected-focus-background: #d1fae5;
    --p-select-overlay-background: var(--content-bg--color-lighter);
    --p-select-overlay-border-radius: var(--radius);
    --p-select-overlay-border-color: var(--color-border-primary);
    --p-select-overlay-shadow: var(--p-overlay-select-shadow);
    --p-select-overlay-color: var(--color-text-primary);
    --p-select-disabled-color: var(--color-text-secondary);
    --p-select-disabled-background: var(--content-bg--color-light);
    --p-select-focus-border-color: var(--p-form-field-focus);
    --p-select-invalid-border-color: var(--color-border-warning);

    // textarea
    --p-textarea-color: var(--color-text-primary);
    --p-textarea-background: transparent;
    --p-textarea-padding-y: 0.4rem;
    --p-textarea-padding-x: 0.4rem;
    --p-textarea-border-color: var(--color-border-secondary);
    --p-textarea-transition-duration: var(--p-transition-duration);
    --p-textarea-border-radius: var(--radius);
    --p-textarea-shadow: var(--p-form-field-shadow);
    --p-textarea-hover-border-color: var(--color-border-focus);
    --p-textarea-focus-border-color: var(--p-form-field-focus);

    // selectbutton
    --p-selectbutton-border-radius: var(--radius);
    --p-togglebutton-border-radius: var(--radius);
    --p-togglebutton-color: var(--color-text-secondary);
    --p-togglebutton-background: var(--content-bg--color-lighter);
    --p-togglebutton-border-color: none;
    --p-togglebutton-padding: 0.5rem 1rem;
    --p-togglebutton-transition-duration: var(--p-transition-duration);
    --p-togglebutton-font-weight: 500;
    --p-togglebutton-gap: 0.5rem;
    --p-togglebutton-hover-background: var(--content-bg--color-lighter);
    --p-togglebutton-hover-color: var(--color-text-primary);
    --p-togglebutton-content-left: 0.25rem;
    --p-togglebutton-content-top: 0.25rem;
    --p-togglebutton-content-checked-background: var(--color-button-bg-submit);
    --p-togglebutton-content-checked-shadow: var(--p-togglebutton-content-checked-shadow);
    --p-togglebutton-checked-background: var(--content-bg--color-lighter);
    --p-togglebutton-checked-color: var(--color-text-quaternary);

    // timeline
    --p-timeline-event-connector-size: 0.1rem;
    --p-timeline-event-connector-color: var(--color-border-secondary);
    --p-timeline-vertical-event-content-padding: 0 1rem;
    --p-timeline-event-min-height: 5rem;

    // toast
    --p-toast-width: 25rem;
    --p-toast-border-width: 1px;
    --p-toast-blur: 1.5px;
    --p-toast-border-radius: var(--radius);
    --p-toast-success-shadow: 0px 4px 8px 0px color-mix(in srgb, #22c55e, transparent 96%);
    --p-toast-success-color: #16a34a;
    --p-toast-success-border-color: #bbf7d0;
    --p-toast-success-background: color-mix(in srgb, #f0fdf4, transparent 15%);
    --p-toast-content-padding: 0.75rem;
    --p-toast-content-gap: 0.5rem;
    --p-toast-success-detail-color: var(--color-text-black);
    --p-toast-success-detail-font-weight: 400;
    --p-toast-detail-font-size: 0.8rem;
    --p-toast-icon-size: 1.125rem;
    --p-toast-warn-shadow: 0px 4px 8px 0px color-mix(in srgb, #eab308, transparent 96%);
    --p-toast-warn-color: #ca8a04;
    --p-toast-warn-border-color: #fef08a;
    --p-toast-warn-background: color-mix(in srgb, #fefce8, transparent 15%);
    --p-toast-warn-detail-color: var(--color-text-black);
    --p-toast-warn-detail-font-weight: 400;
    --p-tooltip-max-width: 20rem;

    // message
    --p-message-border-width: 1px;
    --p-message-border-radius: var(--radius);
    --p-message-info-shadow: 0px 4px 8px 0px color-mix(in srgb, #3b82f6, transparent 46%);
    --p-message-info-color: #2563eb;
    --p-message-info-border-color: #bfdbfe;
    --p-message-info-background: #eff6ffe7;
    --p-message-content-padding: 0.75rem;
    --p-message-content-gap: 0.5rem;
    --p-message-error-simple-color: var(--color-text-warning);
    --p-message-simple-content-padding: 0 0.2rem;
    --p-message-text-sm-font-size: 0.8rem;

    // datatable
    --p-datatable-header-cell-padding: 0.75rem 1rem;
    --p-datatable-header-cell-background: var(--content-bg--color-lighter);
    --p-datatable-header-cell-border-color: transparent;
    --p-datatable-header-cell-color: var(--color-text-secondary);
    --p-datatable-header-cell-gap: 0.5rem;
    --p-datatable-transition-duration: var(--p-transition-duration);
    --p-datatable-row-color: var(--color-text-primary);
    --p-datatable-row-background: transparent;
    --p-datatable-body-cell-border-color: var(--color-border-primary);
    --p-datatable-body-cell-padding: 0.75rem 1rem;

    // tag
    --p-tag-primary-color: blue;
    --p-tag-primary-background: #dfe8ff;
    --p-tag-success-color: var(--color-text-success-tag);
    --p-tag-success-background: var(--color-bg-success-tag);
    --p-tag-font-size: 1rem;
    --p-tag-font-weight: 500;
    --p-tag-border-radius: var(--radius);
    --p-tag-padding: 0.25rem 0.5rem;
    --p-tag-shadow: var(--p-form-field-shadow);
    --p-tag-gap: 0.5rem;
    --p-tag-warn-color: var(--color-text-feedback-tag);
    --p-tag-warn-background: var(--color-bg-feedback-tag);
    --p-tag-danger-color: var(--color-text-warning-tag);
    --p-tag-danger-background: var(--color-bg-warning-tag);

    // speeddial
    --p-speeddial-transition-duration: var(--p-transition-duration);
    --p-speeddial-gap: 0.4rem;
    --p-button-secondary-background: var(--content-bg--color-lighter);
    --p-button-secondary-color: var(--color-text-secondary);
    --p-button-secondary-border-color: transparent;
    --p-button-secondary-hover-background: var(--content-bg--color-focus);
    --p-button-secondary-hover-color: var(--color-text-primary);
    --p-button-secondary-hover-border-color: transparent;
}
