@use "mixins" as *;
.notification {
  display: flex;
  flex-direction: column;
  width: 36.4rem;
}

.notification-title {
  @include subtitle;
  margin-bottom: 1rem;
}

.notification-list {
  display: flex;
  flex-direction: column;
  gap: .4rem;
}
