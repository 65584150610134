@use "mixins" as *;
/* Styles for the draggable part of the scrollbar (thumb) */
::-webkit-scrollbar-thumb {
    border-radius: 10px; /* rounded corners for the thumb */
}

/* Styles for the scrollbar track */
::-webkit-scrollbar-track {
    border-radius: 10px; /* rounded corners for the track */
    border: 2px solid var(--scrollbar-color);
}

/* Firefox requires a different approach */
* {
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: var(--scrollbar-color) transparent; /* thumb and track color */
}
