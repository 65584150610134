@use "mixins" as *;
.car__replacement-icon.-card {
    width: 1.6rem;
    height: 1.6rem;
    color: var(--color-text-submit);
}

.car__delay-work-icon {
    &.-todo {
        color: var(--car-state-todo);
        stroke: var(--car-state-todo-border);
        stroke-width: 30px;
    }

    &.-coming {
        color: var(--car-state-coming);
    }

    &.-waiting {
        color: var(--car-state-waiting);
    }

    &.-breakage {
        color: var(--car-state-breakage);
    }

    &.-expertise {
        color: var(--car-state-expertise);
    }

    &.-repairing {
        color: var(--car-state-repairing);
    }

    &.-reassembly {
        color: var(--car-state-reassembly);
    }

    &.-finished {
        color: var(--car-state-finished);
    }

    &.-delivered {
        color: var(--car-state-delivered);
    }
}

.car__card-state {
    height: .7rem;
    width: .7rem;
    border-radius: 999px;

    &.-todo {
        background-color: transparent;
        border: 1px solid var(--car-state-todo-border);
    }

    &.-coming {
        background-color: var(--car-state-coming);
    }

    &.-waiting {
        background-color: var(--car-state-waiting);
    }

    &.-breakage {
        background-color: var(--car-state-breakage);
    }

    &.-expertise {
        background-color: var(--car-state-expertise);
    }

    &.-repairing {
        background-color: var(--car-state-repairing);
    }

    &.-reassembly {
        background-color: var(--car-state-reassembly);
    }

    &.-finished {
        background-color: var(--car-state-finished);
    }

    &.-delivered {
        background-color: var(--car-state-delivered);
    }

    &.-repairing {
        &.-todo {
            background-color: transparent;
            border: 1px solid var(--car-state-todo-border);
        }

        &.-in-progress {
            background-color: var(--car-state-coming);
        }

        &.-finished {
            background-color: var(--car-state-finished);
        }
    }
}

.car__card-status {
    display: flex;
    align-self: auto;
    justify-content: center;
    align-items: center;
    padding: .2rem;
    border: .4px solid var(--color-border-secondary);
    border-radius: var(--radius-max);
    padding-inline: .4rem;

    &.-available {
       border-color: var(--color-text-success);
    }

    &.-unavailable {
        border-color: var(--color-text-warning);
    }
}

.car__card-status__text {
    @include body;
    font-size: .7rem;
    margin: 0;

    &.-available {
        color: var(--color-text-success);
    }

    &.-unavailable {
        color: var(--color-text-warning);
    }

    &.-dates {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .4rem;
    }
}

.car__quit {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.car__quit-icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-icon);
    padding: .1rem;
    cursor: pointer;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.cars__modal {
    display: flex;
    flex-direction: column;
    border: .4px solid var(--color-border-focus);
    margin-top: .3rem;
    border-radius: var(--radius);
    padding: .5rem;
    background-color: var(--content-bg--color-lighter);
    gap: .4rem;
    z-index: 999;
    overflow-y: auto;

    &.-hidden {
        display: none;
    }

    &.-states {
        position: absolute;
        height: auto;
    }
}

.cars__modal-car {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 24rem;
    padding: .2rem;
    height: 2rem;
    border-radius: var(--radius);
    cursor: pointer;

    &:hover {
        background-color: var(--color-button-bg-focus);
    }
}

.car__modal-details {
    color: var(--color-text-primary);
    opacity: .8;
    margin: 0;

    &.-hidden {
        display: none;
    }
}

.cars__modal-car:hover .car__modal-details {
    opacity: 1;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(100%) brightness(2);
}

.car__card-subtitle {
    @include body;
    font-size: .8rem;
    color: var(--color-text-secondary);
    margin: 0;
}
