@use "mixins" as *;
.header {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 4rem;
    box-sizing: border-box;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-bottom: .8px solid var(--color-homepage-border-header);
    z-index: 999;
}

.header__content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.header__text {
    @include subtitle;
    color: var(--color-homepage-text-secondary);
    white-space: nowrap;

    &.-title {
        @include title;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            color: var(--color-text-primary);
        }
    }

    &:hover {
        color: var(--color-homepage-text-primary);
    }

    &.-button {
        background-color: var(--color-button-bg-submit-homepage);
        color: var(--color-homepage-text-primary);
        opacity: .9;
        padding: .2rem .8rem;

        &:hover {
            opacity: 1;
        }
    }

    &.-cap-height {
        height: 2.5rem;
        padding: 0 1.5rem
    }

    &.-neutral {
        background-color: transparent;
        border: 1px solid var(--color-border-tertiary);
    }
}
