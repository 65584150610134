@use "mixins" as *;
.object__container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.object {
    display: flex;
    align-items: flex-start;
    padding: 2rem 0rem;
    flex-direction: column;
    gap: 1rem;
    width: 60%;
    height: calc(100% - 4rem);
}

.object__block {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;

    &.-header {
        justify-content: space-between;
        width: 100%;
    }

    &.-first {
        margin-top: -1rem;
    }

    &.-column {
        flex-direction: column;
        gap: .5rem;
    }

    &.-full {
        width: 100%;
    }

    &.-wrap {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.object__title {
    @include title;
}

.object__text {
    @include body;
    color: var(--color-text-secondary);

    &.-highlight {
        color: var(--color-text-primary);
    }

    &.-truncate {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 10rem;
    }
}

.object__subtitle {
    @include subtitle;

    &.-low {
        color: var(--color-text-secondary);
    }
}

.object__check-icon {
    width: 1.4rem;
    height: 1.4rem;
    color: var(--color-text-success);
}

.object__go-back-icon {
    transform: rotate(180deg);

    &:hover {
        color: var(--color-icon-focus);
    }
}

@media (max-width: $breakpoint-m) {
    .object {
        width: 50%;
        height: calc(100% - 2rem);
        padding-left: 2rem;
    }
}

@media (max-width: $breakpoint-s) {
    .object {
        width: 100%;
        padding: 1rem;
    }
}
