@use "mixins" as *;
.notes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.notes__title {
    @include subtitle;
}

.note {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    width: 15rem;
    height: 7.5rem;
    background-color: var(--color-bg-note);
    padding: .6rem .4rem .4rem;
    border-radius: var(--radius);

    &.-long {
        width: 30rem;
        height: auto;
    }

    &.-focusable:hover {
        cursor: pointer;
        background-color: var(--color-bg-note-focus);
    }

    &.-comment {
        width: 100%;
        height: auto;
    }
}

.note__container {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    height: 80%;
}

.note__comment-post {
    display: flex;
    flex-direction: column;
    width: 30rem;
    gap: .4rem;
    margin-top: .8rem;
}

.note__comment-input {
    width: 100%;
}

.note__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .2rem;
    height: 20%;
}

.note.-comment .note__header {
    height: auto;
}

.note__header-left {
    display: flex;
    flex-direction: row;
    gap: .4rem;
    justify-content: flex-start;
    align-items: center
}

.note__avatar {
    @include subbody;
    display: flex;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 999px;
    justify-content: center;
    align-items: center;
    color: var(--color-text-quaternary)
}

.note__name {
    @include subbody;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.note__date {
    @include subbody;
    color: var(--color-text-secondary);
    text-align: right;
}


.note__delete-icon {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--color-icon);
}
.note__delete:hover .note__delete-icon {
    color: var(--color-icon-focus);
}

.note__content {
    @include body;
    color: var(--color-text-primary);
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &.-comment {
        height: auto;
        max-width: 25rem;
    }
}

.note__comment {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: .2rem;
    height: 20%;
    padding-right: .4rem;
}

.note__comment-icon {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--color-icon-focus);
}

.note__edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .4rem;
}

.note__edit-icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-icon);
}

.note__edit-button:hover .note__edit-icon {
    color: var(--color-icon-focus);
}

.note__comment-text {
    @include body;
}

.note__comments {
    display: grid;
    grid-template-columns: 1fr 20fr;
    width: 30rem;
}

.note__comments-line {
    width: .2rem;
    margin-left: calc(100% - .2rem);
    height: 100%;
    background-color: var(--color-border-quaternary);

    &.-horizontal {
        width: 10%;
        height: .2rem;
        margin-left: 0;

        &.-last {
            width: 100%;
        }
    }
}

.note__comments-list {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    margin-top: .8rem;
}

.note__comment-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &.-last {
        align-items: stretch;
    }
}

.note__comments-last {
    display: flex;
    width: 10%;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column;
}

.note__comments-hidding {
    display: flex;
    width: 100%;
    height: 50%;
    background-color: var(--content-bg--color-lighter);
    transform: translateX(-50%);
}
