@use "mixins" as *;
.menu__container {
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    flex-direction: column;
    width: 14rem;
    gap: .6rem;
    overflow-y: auto;
}

.menu__header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    align-items: center;
    width: calc(100% - .3rem);
    margin: 1rem 0;
    padding-left: .3rem;
}

.company__name {
    @include subtitle;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    margin: 0;
    opacity: .9;
    line-height: 2rem;
    max-width: 14rem;
    text-align: left;
    padding-inline: 0;

    &:hover {
        background-color: transparent;
        opacity: 1;
    }
}

.menu__section {
    @include body;
    width: 95%;
    opacity: .9;
    padding-inline: 0;
    padding: .2rem 0 .2rem .3rem;

    &:hover {
        opacity: 1;
    }

    &.-focus {
        background-color: var(--color-button-bg-focus);
        opacity: 1;
    }
}

.menu__section-icon {
    color: var(--color-icon);
    width: 1.2rem;
    height: 1.2rem;
}

.menu__section:hover .menu__section-icon, .menu__section.-focus .menu__section-icon {
    color: var(--color-icon-hover);
}

.menu__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: .4rem;
    height: 100%;
    width: 100%;
    margin-top: 4rem;
}

.user__status {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .8rem;
}

.user__status-point {
    width: .4rem;
    height: .4rem;
    border-radius: 50%;
    background-color: var(--status-connected);
}

.user__status-text {
    @include body;
    color: var(--color-text-secondary);
    margin: 0;
}

.menu__delimiter {
  width: 95%;
  height: 2px;
  background-color: var(--color-border-secondary);
  border-radius: var(--radius);
}

@media (max-width: $breakpoint-m) {
    .menu__container {
        display: none;
        position: absolute;
        z-index: 999;
        height: 100%;
        background-color: var(--content-bg--color);
        width: 20rem;
        border-right: .4px solid var(--color-border-primary);
        top: 0;
        left: 0;
        padding-top: 1rem;

        &.-display {
            display: flex;
        }
    }
}

@media (max-width: $breakpoint-mobile) {
    .menu__container {
        padding: 1rem;
    }

    .company__name {
        max-width: 10rem;
    }
}
