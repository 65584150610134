@use "mixins" as *;
.questions, .question-object {
    width: 100vw;
    height: 90vh;
    padding: 4rem 2rem;
    overflow-y: auto;
}

.questions__back-button, .question__back-button {
    @include body;
    color: var(--color-text-secondary);
    text-align: center;
    padding: 0.4rem 1rem;
    border-radius: var(--radius-max);
    gap: 0.4rem;
    margin-bottom: 2rem;
    width: fit-content;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.questions__icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-icon);

    &.-rotate {
        transform: rotate(180deg);
    }

    &.-small {
        width: 1.4rem;
        height: 1.4rem;
    }
}

.questions__back-button:hover .questions__icon {
    color: var(--color-icon-focus);
}

.questions__list, .question-object__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.question-object__content {
    gap: 2rem;
}

.questions__searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--content-bg--color-lighter);
    border: 1px solid var(--color-border-tertiary);
    border-radius: var(--radius-max);
    display: flex;
    gap: 0.4rem;
    padding: 0.4rem 0.8rem;
    width: 80%;
    height: 2rem;
}

.questions__searchbar-input {
    @include body;
    background-color: var(--content-bg--color-lighter);
    display: flex;
    border: none;
    resize: none;
    outline: none;
    color: var(--color-text-primary);
    width: 80%;
}

.questions__searchbar-select {
    @include body;
    background-color: var(--content-bg--color-lighter);
    border: none;
    resize: none;
    outline: none;
    color: var(--color-text-primary);
    border: 1px solid var(--color-border-tertiary);
    padding: 0.2rem 0.4rem;
    width: 20%;
    border-radius: var(--radius);

    &:hover {
        cursor: pointer;
    }
}

.questions__tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.questions__search-count {
    @include title;
}

.questions__button {
    @include body;
    background-color: var(--color-button-bg-submit-homepage);
    color: var(--color-homepage-text-primary);
    opacity: .9;
    padding: .2rem .8rem;

    &:hover {
        opacity: 1;
        background-color: var(--color-button-bg-submit-homepage);
    }
}

.questions__message {
    @include body;
    color: var(--color-text-success);
    margin-top: 2rem;
}

.questions__items, .question-object__question {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 80%;
}

.question-object__question {
    max-width: 50rem;
}

.question {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: .8rem;
    opacity: .9;

    &:hover {
        background-color: var(--color-button-bg-focus);
        opacity: 1;
    }
}

.question-object__icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-border-success);
}

.question__author {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.4rem;
    margin-top: -1rem;
}

.question__avatar {
    @include subbody;
    font-size: .5rem;
    line-height: .5rem;
    display: flex;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    &.-comment {
        width: 1.4rem;
        height: 1.4rem;
    }
}

.question__author-name {
    @include subbody;
}

.question__date {
    @include subbody;
    color: var(--color-text-secondary);
}

.question__header, .question-object__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 0.4rem;
}

.question-object__header {
    justify-content: space-between;
}

.question__category, .question-object__category {
    @include subbody;
    background-color: var(--color-button-bg-object);
    padding: 0 0.4rem;
    border-radius: var(--radius-max);
}

.question-object__category {
    @include body;
    padding: .4rem .6rem;
}

.question__description {
    @include body;
    max-height: 3.2rem;
    max-width: 100%;
    color: var(--color-text-secondary);
    white-space: no-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.question__informations {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.-no-button {
        justify-content: flex-end;
    }
}

.question__button {
    @include body;
    background-color: var(--color-button-bg-submit);
    color: var(--color-text-primary);
    opacity: .9;
    padding: .2rem .8rem;
    align-self: start;

    &:hover {
        opacity: 1;
        background-color: var(--color-button-bg-submit-homepage);
    }
}

.question__details {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: .4rem;
}

.question__detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .1rem;
}

.question__detail-icon {
    width: 1.4rem;
    height: 1.4rem;
    color: var(--color-icon-focus);
}

.question__detail-text {
    @include body;
}

.question-object__title, .question__title {
    @include title;
    display: flex;
    align-items: center;
    gap: .8rem;
    flex-direction: row;
}

.question__title {
    @include subtitle;
    gap: .4rem;
}

.question-object__description {
    @include body;
    color: var(--color-text-secondary);
    white-space: pre-wrap;
}

.question__comment {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--content-bg--color-lighter);
    width: 80%;
    max-width: 50rem;
    border-radius: var(--radius);
}

.question__comment-input {
    background-color: transparent;
    border: none;
}

.question__comment-content {
    @include body;
    color: var(--color-text-secondary);
    margin-top: 1rem;
}

@media (max-width: $breakpoint-m) {
    .questions__searchbar-input {
        width: 60%;
    }

    .questions__searchbar-select {
        width: 40%;
    }
}

@media (max-width: $breakpoint-mobile) {
    .questions {
        padding: 4rem 0rem;
    }

    .questions__searchbar-input {
        width: 90%;
    }

    .questions__searchbar-select {
        display: none;
    }

    .questions__search-count {
        @include body;
    }

    .questions__button {
        @include subbody;
        width: 8rem;
    }

    .question {
        padding: 0;
    }

    .question-object__header {
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;
    }

    .question__informations {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .question__details {
        justify-content: flex-start;
    }

    .question__detail-text.-date {
        display: none;
    }
}
