@use "mixins" as *;
.body__container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    background-color: var(--content-bg--color-light);
    border: .5px solid var(--color-border-primary);
    border-radius: var(--radius);
    overflow: hidden;
}

.body__toolbar {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    border-bottom: .4px solid var(--color-border-primary);

    &.-no-bar {
        border: none;
    }
}

.body__content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    &.-admin {
        overflow-y: auto;
    }
}

.body__toolbar-button {
    @include body;
    color: var(--color-text-secondary);
    border: .4px solid var(--color-border-secondary);

    &:hover {
        color: var(--color-text-primary);
    }

    &.-focus {
        border-color: var(--color-border-submit);
        color: var(--color-text-submit);
    }
}

.body__toolbar-button-top {
    @include body;
}

.body__toolbar-button-menu {
    display: none;
    height: 2.2rem;
    width: 2.2rem;
    color: var(--color-icon);

    &.-quit {
        align-self: flex-start;
        margin-right: 1rem;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
    }

    &.-persistent {
        display: flex;
    }
}

.company {
    display: flex;
    flex: 1;
    height: calc(100vh - 28px);
    width: calc(100vw - 28px);
    flex-direction: row;
}

@media (max-width: $breakpoint-m) {
    .body__toolbar-button-menu {
        display: flex;
    }
}

@media (max-width: $breakpoint-s) {
    .company {
        width: 100%;
    }
}
