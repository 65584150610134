@use "mixins" as *;
.user {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.user__message {
    @include subtitle;
    font-weight: 400;
    max-width: 30rem;

    &.-sub {
        @include body;
        color: var(--color-text-secondary);
    }
}

.user__button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}

.user__button {
    background-color: var(--color-button-bg-submit);
    color: var(--color-text-quaternary);
    opacity: .9;
    padding: .5rem 1rem;

    &:hover {
        background-color: var(--color-button-bg-submit);
        opacity: 1;
    }
}
