@use "mixins" as *;
:root {
    --cars-width: 15rem;
    --section-height: 2.3rem;
}

.replacements__navigator {
    display: flex;
    flex: 1;
    height: 2rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.replacements__navigator-date {
    @include subtitle;
    width: 10rem;
    text-align: center;
}

.replacements__navigator-icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-icon);

    &.-left {
        transform: rotate(180deg);
    }
}

a:hover .replacements__navigator-icon {
    color: var(--color-icon-focus);
}

.replacements__week {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1rem;
    padding: .6rem;
    border-radius: var(--radius);
    min-height: 40rem;

    &.-highlight {
        border: .4px solid var(--color-button-bg-submit-full);
    }
}

.replacements__week-title {
    @include title;
    background-color: var(--content-bg--color-light);
}

.replacements__week-container {
    display: flex;
    flex-direction: row;
}

.replacements__week-days {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    width: 100%;
    background-color: var(--content-bg--color-light);
    padding-left: var(--cars-width);
    padding-top: 1rem;
    position: sticky;
    top: -1.1rem;
    z-index: 1;
}

.replacements__week-day {
    display: flex;
    justify-content: center;
}

.replacements__week-day-title {
    @include body;
    height: 3rem;
}

.replacements__week-cars {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.replacements {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: .4rem;
    align-items: flex-start;
}

.replacement__car {
    @include body;
    border: .4px solid var(--color-border-primary);
    width: var(--cars-width);
    padding: .6rem .4rem;
    gap: .4rem;
    border-radius: var(--radius);
    display: flex;
    justify-content: space-between;
    height: var(--section-height);
}

.replacement__car-name {
    width: calc(100% - 1rem);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.replacement__sections-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - var(--cars-width));
    gap: .4rem;
}

.replacement__sections {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: .4rem;
    height: var(--section-height);
}

.replacement__modal {
    border-radius: var(--radius);
    border: 1px solid var(--color-text-submit);
    width: 100%;

    &.-hidden {
        display: none;
    }

    &.-left {
        border-top-left-radius: 0;
    }

    &.-right {
        border-top-right-radius: 0;
    }
}

.replacement__section {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius);
    padding: .4rem;
    height: 100%;

    &.-empty {
        width: calc(20% - 4.2px);
        border: 1px dashed var(--color-text-submit);

        &:hover {
            background-color: var(--content-bg--color-light);
            border-color: var(--color-text-submit-focus);
        }

        &.-focus {
            border-style: solid;
            background-color: var(--content-bg--color-light);
            border-radius: var(--radius) var(--radius) 0 0;
            height: calc(var(--section-height) + 1rem);
            border-bottom: none;
            position: sticky;
            align-items: flex-start;
            z-index: 2;

            &:hover {
                background-color: var(--content-bg--color-light);
                border-color: var(--color-text-submit);
            }
        }
    }

    &.-car {
        &.-finished {
            background-color: var(--car-state-finished-shaded);
            &:hover {
                background-color: var(--car-state-finished);
            }
        }

        &.-in-progress {
            background-color: var(--car-state-coming-shaded);
            &:hover {
                background-color: var(--car-state-coming);
            }
        }

        &.-coming {
            border: .4px solid var(--car-state-todo-border);
            &:hover {
                background-color: var(--color-button-bg-focus);
            }
        }
    }

    &.-maintenance {
        border: .4px solid var(--color-bg-warning);
        color: var(--color-text-warning);
    }
}

.car__state.-replacement {
    display: flex;
    width: 1.2rem;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.replacement__section-icon {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--color-text-warning);
}

.replacement__section.-empty.-focus::before {
    content: "";
    position: absolute;
    background-color: transparent;
    height: calc(var(--section-height) + .2rem);
    top: .2rem;
    left: 100%;
    width: 2.6rem;
    border-bottom-left-radius: var(--radius);
    z-index: 999;
    border-left: 1px solid var(--color-text-submit);
    border-bottom: 1px solid var(--color-text-submit);
    box-shadow: -30px 10px 0 0 var(--content-bg--color-light);
}

.replacement__section.-empty.-focus:last-child::before {
    display: none;
}

.replacement__section.-empty.-focus::after {
    content: "";
    position: absolute;
    background-color: transparent;
    height: calc(var(--section-height) + .2rem);
    top: .2rem;
    right: 100%;
    width: 2.6rem;
    border-bottom-right-radius: var(--radius);
    z-index: 999;
    border-right: 1px solid var(--color-text-submit);
    border-bottom: 1px solid var(--color-text-submit);
    box-shadow: 30px 10px 0 0 var(--content-bg--color-light);
}

.replacement__section.-empty.-focus:first-child::after {
    display: none;
}

.replacement__section-text {
    @include body;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.-empty {
        color: var(--color-text-submit);
    }
}

.replacement__section.-empty:hover .replacement__section-text.-empty {
    color: var(--color-text-submit-focus);
}

.replacement__modal-header {
    display: flex;
    justify-content: space-between;
    padding: .4rem;
}

.replacement__modal-title {
    @include subtitle;
}

.replacement__modal-quit-button {
    height: 1.6rem;
    justify-content: center;
}

.replacement__modal-quit-icon {
    width: 1rem;
    height: 1rem;
    color: var(--color-icon);
    padding: .1rem;
    cursor: pointer;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.replacement__modal-quit-button:hover .replacement__modal-quit-icon {
    color: var(--color-icon-focus);
}

.replacement__modal-cars {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: .4rem;
    gap: .4rem;
}

.replacement__day {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    width: 20%;

    &.-empty {
        opacity: .6;
    }
}

.replacement__no-replacements {
    @include body;
    width: 100%;
    text-align: center;
    padding: 1rem;
}

.replacement__day-name {
    @include body;
    text-align: center;
}

.replacement__car-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 1.6rem;
    border: .4px solid var(--color-border-tertiary);
    width: 100%;
    gap: .4rem;
    padding: .4rem;
    border-radius: var(--radius);

    &:hover {
        border-color: var(--color-border-focus);
        background-color: var(--color-button-bg-focus);
        cursor: pointer;
    }
}

.car__name.-replacement {
    width: calc(100% - 2.4rem - 0.4rem);
}

.replacement__modal-insurance-match {
    width: 1.2rem;
}

.cars__modal.-replacements {
    max-height: 12rem;
    width: 33rem;
}

.cars__modal-car.-replacements {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    width: 31rem;
    background-color: var(--content-bg--color-light);
    border-radius: var(--radius);
    border: .4px solid var(--color-border-secondary);
    padding: .2rem .4rem;

    &:hover {
        border-color: var(--color-border-focus);
    }

    &.-hidden, &.-selected {
        display: none;
    }
}

.car__modal-details.-replacement-car {
    justify-content: flex-start;
    display: flex;
}

.car__modal-time-range-icon {
    width: 1.2rem;
    height: 1.2rem;
    color: var(--color-text-warning)
}

.replacements__navigator-add {
    width: 1rem;
    height: 1rem;
}

@media (max-width: $breakpoint-s) {
    .replacements__navigator {
        display: none;
    }

    .replacements__week-days {
        padding-left: 0;
        width: calc(100% + 1rem);
        transform: translateX(-.5rem);
    }

    .replacements {
        flex-direction: column;
    }

    .replacement__sections-container {
        width: 100%;
    }

    .replacement__sections {
        width: 100%;
    }

    .replacement__car {
        width: 100%;
        justify-content: center;
    }
}
