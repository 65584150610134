@use "mixins" as *;
.p-inputtext {
  width: 100%;
  @include body;
  font-size: .8rem !important;

  &::placeholder {
    color: var(--color-text-secondary) !important;
  }
}

.p-floatlabel {
  @include body;

  label {
    display: flex;
    align-items: center;
    gap: .4rem;
  }
}

.p-autocomplete-overlay,
.p-datepicker-panel {
  margin-top: .2rem;
}

.p-tooltip {
  max-width: fit-content;
}

.p-tooltip-text {
  width: fit-content !important;
  @include body;
}

.p-datepicker-input {
  @include body;
}

.p-datepicker-dropdown {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left-width: 0 !important;
}

.toolbar > label {
  --p-floatlabel-on-active-background: var(--content-bg--color-light);
}

.p-textarea {
  font-size: .8rem !important;

  &::placeholder {
    color: var(--color-text-secondary) !important;
  }
}

.p-togglebutton {
  font-size: .8rem !important;
}

.p-dialog-header {
  align-items: flex-start !important;
}

.p-timeline {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  direction: ltr;
}

.p-timeline-horizontal {
  flex-direction: row;
}

.p-timeline-event {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  max-width: 10rem;
  min-width: 8.5rem;
}

.p-timeline-event-separator {
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  min-height: 2rem;
}

.p-timeline-horizontal .p-timeline-event-content,
.p-timeline-horizontal .p-timeline-event-opposite {
  @include body;
  padding: .4rem 0;
}

.p-timeline-horizontal .p-timeline-event-opposite {
  @include body;
}

.p-timeline-vertical > .p-timeline-event {
  flex-direction: row;
}

.p-timeline-left .p-timeline-event-opposite {
  text-align: left;
}

.p-timeline-horizontal .p-timeline-event-separator {
  flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event-connector {
  background: var(--color-text-primary);
  width: 100%;
  height: 1px;
}

.p-message-text {
  display: flex;
  align-items: center;
  gap: .4rem;
}

.p-message-simple {
  display: inline-block;
  max-width: 26rem;
}

.p-inputtext.p-invalid + label {
  color: var(--color-text-warning) !important;
}

.p-datatable-header-cell:first-of-type {
  border-start-start-radius: var(--radius);
  border-end-start-radius: var(--radius);
}

.p-datatable-header-cell:last-of-type {
  border-start-end-radius: var(--radius);
  border-end-end-radius: var(--radius);
}

.p-speeddial-list {
  position: absolute !important;
}

.p-speeddial-direction-left > .p-speeddial-list {
  transform: translateX(-70%) !important;
}

.p-speeddial-open .p-speeddial-list {
  backdrop-filter: blur(2px);
}
