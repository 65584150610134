@use "mixins" as *;
button, a {
    display: flex;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-radius: var(--radius);
    text-decoration: none !important;
    padding-block: 1px;
    padding-inline: 6px;
    border-width: 0;

    &:hover {
        background-color: var(--color-button-bg-focus);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: .5;
    }

    &.-highlighted {
        background-color: var(--color-button-bg-submit);
        color: var(--color-text-quaternary);

        &:hover {
            background-color: var(--color-button-bg-submit-full-hover);
            color: var(--color-text-quaternary);
        }
    }

    &.-deletion {
        background-color: var(--color-bg-warning);
        color: var(--color-text-quaternary);
        opacity: .9;

        &:hover {
            background-color: var(--color-bg-warning);
            color: var(--color-text-quaternary);
            opacity: 1;
        }
    }
}
