@use "mixins" as *;
.insurance__selecter {
    gap: .4rem;
    display: flex;
    flex-direction: column;
}

.insurances__box {
    display: flex;
    width: 26rem;
    flex-wrap: wrap;
    white-space: nowrap;
    padding-bottom: .6rem;
    gap: .6rem;
}

.insurance__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-button-bg-object);
    color: var(--color-text-quaternary);
    padding: 0.8rem;
    border-radius: var(--radius-max);
    gap: 0.4rem;
    height: 1.1rem;
    white-space: nowrap;
    width: fit-content;

    &.-hidden {
        display: none;
    }

    &.-replacement {
        margin-bottom: .4rem;
    }
}

.color, .insurances-group__color, .car__card-state {
    &.-dark-grey {
        background-color: var(--insurances-group-dark-grey);
    }

    &.-grey {
        background-color: var(--insurances-group-grey);
    }

    &.-purple {
        background-color: var(--insurances-group-purple);
    }

    &.-teal {
        background-color: var(--insurances-group-teal);
    }

    &.-green {
        background-color: var(--insurances-group-green);
    }

    &.-yellow {
        background-color: var(--insurances-group-yellow);
    }

    &.-orange {
        background-color: var(--insurances-group-orange);
    }

    &.-pink {
        background-color: var(--insurances-group-pink);
    }

    &.-red {
        background-color: var(--insurances-group-red);
    }
}

.color__button:hover {
    &.-dark-grey {
        box-shadow: var(--insurances-group-dark-grey) 0px 0px 3px 3px;
    }

    &.-grey {
        box-shadow: var(--insurances-group-grey) 0px 0px 3px 3px;
    }

    &.-purple {
        box-shadow: var(--insurances-group-purple) 0px 0px 3px 3px;
    }

    &.-teal {
        box-shadow: var(--insurances-group-teal) 0px 0px 3px 3px;
    }

    &.-green {
        box-shadow: var(--insurances-group-green) 0px 0px 3px 3px;
    }

    &.-yellow {
        box-shadow: var(--insurances-group-yellow) 0px 0px 3px 3px;
    }

    &.-orange {
        box-shadow: var(--insurances-group-orange) 0px 0px 3px 3px;
    }

    &.-pink {
        box-shadow: var(--insurances-group-pink) 0px 0px 3px 3px;
    }

    &.-red {
        box-shadow: var(--insurances-group-red) 0px 0px 3px 3px;
    }
}

.cars__modal.-insurances {
    max-height: 20rem;
}

#insurances-modal-content {
    &.-absolute {
        position: absolute;
        transform: translateY(5rem);
        z-index: 999;
    }
}

.form__label.-replacement-car {
    @include body;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: .4px solid var(--color-border-secondary);
    border-radius: var(--radius);
    padding: .4rem;
    background-color: var(--content-bg--color-light);

    &.-hidden {
        display: none;
    }
}

.form__label-text {
    width: 15rem;
}

.form__label-replacement-car-delete:hover svg {
    color: var(--color-icon-focus);
}

.insurances-groups__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    overflow: auto;
    overflow-x: hidden;
    width: 100%;  
}

.insurances-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: var(--radius);
    border: .4px solid var(--color-border-primary);
    width: 25rem;
}

.insurances-group__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .8rem;
}

.insurances-group__color {
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 999px;
}

.insurances-group__name {
    @include subtitle;
}

.insurances-group__insurances {
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    gap: .6rem;
}

.insurances__group-subtitle {
    @include body;
}

.insurances__group-replacements-cars {
    display: flex;
    flex-direction: column;
    gap: .4rem;
}

.insurances__group-replacements-car {
    @include body;
    width: 100%;
    padding: .2rem .4rem;
    border: .4px solid var(--color-border-secondary);
    text-align: left;
    border-radius: var(--radius);
}

.insurances-group__button {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    flex-grow: 1;
}

.insurances-group-deletion {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.insurances-group__button-deletion {
    width: fit-content;
}

.insurances-group__icon {
    width: 2rem;
    height: 2rem;
    color: var(--color-text-warning);
}

.form.-insurances-group {
    width: 33rem;
}

.insurances-groups__go-back-button {
    @include body;
    color: var(--color-text-secondary);
    text-align: center;
    padding: 0.4rem 1rem;
    border-radius: var(--radius-max);
    gap: 0.4rem;
    width: fit-content;
    margin: 1rem;

    &:hover {
        color: var(--color-icon-focus);
        background-color: var(--color-button-bg-focus);
    }
}

.insurances-groups__go-back-icon {
    width: 1.4rem;
    height: 1.4rem;
    transform: rotate(180deg);
}
