@use "mixins" as *;
#staff-app {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.staff-inactive > td:not(:nth-last-child(-n+2)) {
    opacity: 0.5 !important;
}

.staff-inactive > td:nth-last-child(-n+2) {
    border-color: color-mix(in srgb, var(--color-border-primary) 50%, transparent 50%) !important;
}

.staff-timeline-content > .p-timeline-event > .p-timeline-event-opposite {
    max-width: 12rem !important;
    min-width: 12rem !important;
    padding-left: 0 !important;
}

.staff-timeline-content > .p-timeline-event > .p-timeline-event-content {
    max-width: 20rem !important;
    min-width: 20rem !important;
}

.staff-timeline-content.-dialog > .p-timeline-event > .p-timeline-event-content {
    max-width: 0 !important;
    min-width: 0 !important;
}

.staff-timeline-content > .p-timeline-event:nth-child(odd) {
    min-height: 3rem !important;
}
